@font-face {
  font-family: 'Brandon Grostesque Bold';
  src:
    local('Brandon Grostesque Bold'),
    url('./Brandon_bld.otf') format('opentype'),
    url('./Brandon_bld_it.otf') format('otf');
}

@font-face {
  font-family: 'Brandon Grostesque Regular';
  src:
    local('Brandon Grostesque Regular'),
    url('./Brandon_reg.otf') format('opentype'),
    url('./Brandon_reg_it.otf') format('otf');
}

@font-face {
  font-family: 'Brandon Grostesque Black';
  src:
    local('Brandon Grostesque Black'),
    url('./Brandon_blk.otf') format('opentype'),
    url('./Brandon_blk_it.otf') format('otf');
}

@font-face {
  font-family: 'Brandon Grostesque Light';
  src:
    local('Brandon Grostesque Light'),
    url('./Brandon_light.otf') format('opentype'),
    url('./Brandon_light_it.otf') format('otf');
}

@font-face {
  font-family: 'Brandon Grostesque Medium';
  src:
    local('Brandon Grostesque Medium'),
    url('./Brandon_med.otf') format('opentype'),
    url('./Brandon_med_it.otf') format('otf');
}

@font-face {
  font-family: 'Brandon Grostesque Thin';
  src:
    local('Brandon Grostesque Thin'),
    url('./Brandon_thin.otf') format('opentype'),
    url('./Brandon_thin_it.otf') format('otf');
}
